<template>
  <div class="row">
    <div class="col-md-12">
      <!--begin::Signup-->
      <div class="card card-custom gutter-b example example-compact">
        <div class="card-header">
          <div class="card-title">
            Créer une nouvelle communication (Email ou SMS)
          </div>
        </div>
        <div class="card-body">
          <form
            class="form"
            novalidate="novalidate"
            id="create_com_form"
            @submit.stop.prevent="onSubmitCreate()"
          >
            <div class="pb-13 pt-lg-0 pt-5"></div>
            <div class="form-group">
              <b-form-group
                id="input-group-1"
                label-for="subject"
                description="Entrer un objet ou titre pour le message à envoyer"
              >
                <b-form-input
                  id="subject"
                  placeholder="Objet - Titre"
                  name="subject"
                  ref="subject"
                  autocomplete="off"
                  v-model="form.subject"
                  type="text"
                  required
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="form-group">
              <b-form-select
                v-model="form.selected"
                :options="form.options"
              ></b-form-select>
            </div>
            <div class="form-group">
              <b-form-select
                v-model="form.tag"
                :options="form.tagsOptions"
              ></b-form-select>
            </div>
            <wysiwyg v-model="form.communication_message" />
            <div class="form-group d-flex flex-wrap pb-lg-0 pb-3">
              <button
                ref="kt_login_signup_submit"
                class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                style="width:150px;"
              >
                Enregistrer
              </button>
              <button
                type="button"
                id="kt_login_signup_cancel"
                class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                @click="showForm('signup')"
              >
                Annuler
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "Create",
  data() {
    return {
      form: {
        communication_message: "",
        selected: null,
        tag: null,
        options: [
          { value: null, text: "Sélectionner un type" },
          { value: "email", text: "Email" },
          { value: "sms", text: "SMS" }
        ],
        tagsOptions: [
          { value: null, text: "Sélectionner une cible" },
          { value: "all", text: "Tous" },
          { value: "new", text: "Nouveaux inscrits" },
          {
            value: "week-before",
            text: "Non connectés depuis une semaine ou plus"
          }
        ]
      }
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Créer une communication" }
    ]);
  }
};
</script>

<style scoped></style>
